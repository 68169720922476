export const notificationDescriptions = {
  webshop_added: "New webshops are added to this filter",
  best_change: "Change in best checkout position",
  rank_change: "Change in my checkout position",
  new_providers: "New providers are added",
  return_address_change: "Return address changes",
  shipping_methods_change: "Shipping methods changes",
  exporting_countries_change: "Export countries changes",
  include_collect_yourself: "Include change to collect yourself",
  include_unknown: "Include change to unknown provider",
}

export const availableNotifications = {
  'filter': ['webshop_added'],
  'list': ['best_change', 'rank_change', 'new_providers', 'return_address_change', 'shipping_methods_change', 'exporting_countries_change'],
  'webshop': ['best_change', 'rank_change', 'new_providers', 'return_address_change', 'shipping_methods_change', 'exporting_countries_change'],
}

export const defaultNotifications = (modules) => {
  return {
    "notifications": {
      "best_change": {
        "include_unknown": false,
        "notifications_on": modules.includes('checkout') ? true : false,
        "notifications_email": modules.includes('checkout') ? true : false,
        "include_collect_yourself": false
      },
      "rank_change": {
        "include_unknown": false,
        "notifications_on": modules.includes('checkout') ? true : false,
        "notifications_email": modules.includes('checkout') ? true : false,
        "include_collect_yourself": false
      },
      "new_providers": {
        "notifications_on": modules.includes('checkout') ? true : false,
        "notifications_email": modules.includes('checkout') ? true : false
      },
      "return_address_change": {
        "notifications_on": true,
        "notifications_email": true
      },
      "shipping_methods_change": {
        "notifications_on": modules.includes('checkout') ? true : false,
        "notifications_email": modules.includes('checkout') ? true : false
      },
      "exporting_countries_change": {
        "notifications_on": true,
        "notifications_email": true
      }
    },
    "notifications_on": true
  }
}

export const checkoutNotifications = ['best_change', 'rank_change', 'new_providers', 'shipping_methods_change']