import { differenceInHours, format, formatDistance, formatDistanceStrict, getTime, addHours, subHours, startOfMonth, subMonths } from 'date-fns'

export function getSection(key, object) {
  if (!key) {
    return object
  }

  return key
    .split('.')
    .reduce((o, k) => o?.[k], object);
}

export function uniquefyArray(arr) {
  return Array.from(new Set(arr));
}

function formatUtcTime(timestamp) {
  if (timestamp[timestamp.length - 1] === "Z") {
    return timestamp
  }

  return timestamp + "Z"
}

export function timeAgo(timestamp) {
  if (!timestamp) return;

  return formatDistance(new Date(formatUtcTime(timestamp)), Date.now(), { addSuffix: true })
}

export function timeAgoStrict(timestamp, suffix = false) {
  if (!timestamp) return;

  return formatDistanceStrict(new Date(formatUtcTime(timestamp)), Date.now(), { addSuffix: suffix })
}

export function formatTimestamp(timestamp, formatStr) {
  if (!timestamp) return;

  const date = new Date(timestamp);
  return format(date, formatStr);
}

export function getInitials(name) {
  if (!name) return '';

  return name.split(" ").map(n => n[0]).slice(0, 2).join("")
}

export function debounce(fn, wait) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  }
}

export function filterAndReplace(s, filter) {
  if (!s) return;

  return s.split(", ").filter(x => x.includes(filter)).map(x => x.replace(/\s*\([^)]*\)/g, '')).join(", ")
}

export function formatShippingProviders(terms, checkout) {
  const termsProviders = terms?.split(", ") || []
  const checkoutProviders = checkout?.split(", ") || []

  const providers = [...new Set([...termsProviders, ...checkoutProviders])]

  return providers.map(provider => {
    if (termsProviders.includes(provider) && checkoutProviders.includes(provider)) {
      return `${formatName(provider)} (terms & checkout)`
    }
    else if (termsProviders.includes(provider)) {
      return `${formatName(provider)} (terms)`
    }
    else {
      return `${formatName(provider)} (checkout)`
    }
  }).join(", ")
}

export function capitalize(str) {
  if (!str) return;

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatName(str) {
  if (!str) return;

  return (str.charAt(0).toUpperCase() + str.slice(1)).replace(/_/g, " ");
}

export function removeTLDFromURL(url) {
  var tldRegex = /\.[^.]+$/;

  var urlWithoutTLD = url.replace(tldRegex, '');

  return urlWithoutTLD;
}

export function addLinebreaksString(str) {
  return str ? str.split(", ").map(x => formatName(x)).join(",\n") : ''
}

export function formatShippingPositionsName(raw) {
  if (!raw) return '';

  const names = raw.split(", ").map(x => x.split(";")[0])

  return names.map((name, i) => `${i + 1}. ${formatName(name)}`).join("\n")
}

export function formatShippingPositionsAll(raw) {
  if (!raw) return '';

  const rows = raw.split(", ").map(x => x.split(";"))

  return rows.map((row, i) => `${i + 1}. ${formatName(row[0])}: ${formatName(row[1])} (${row[2]} ${row[3]})`).join("\n")
}

export function formatNotificationSettings(n) {
  if (!n || !n.notifications_on) return "";

  return Object.keys(notificationDescriptions).filter(x => n[x]).map(x => notificationDescriptions[x]).join("\n")
}

export function getCountryDifference(str1, str2) {
  const set1 = str1 ? new Set(str1.split(', ')) : new Set([]);
  const set2 = str2 ? new Set(str2.split(', ')) : new Set([]);

  const differenceSet1 = new Set([...set1].filter(country => !set2.has(country)));
  const differenceSet2 = new Set([...set2].filter(country => !set1.has(country)));

  const differenceArray1 = Array.from(differenceSet1);
  const differenceArray2 = Array.from(differenceSet2);

  return {
    countriesRemoved: differenceArray1.join(", "),
    countriesAdded: differenceArray2.join(", ")
  };
}

export function calculateDateAnnotationRange(date, dateMin, dateMax) {
  const hours = Math.max(96, differenceInHours(new Date(dateMax), new Date(dateMin)))

  return { x: getTime(addHours(new Date(date), hours / 36)), x2: getTime(subHours(new Date(date), hours / 36)) }
}

export function countNumbersInString(inputString) {
  const digitMatches = inputString.match(/\d/g);

  if (digitMatches) {
    return digitMatches.length;
  } else {
    return 0;
  }
}

export function calculatePercentageDifference(num1, num2) {
  if (num1 === 0) {
    return 0
  }
  return Math.round(((num2 - num1) / num1) * 100);
}

export function getLastSixMonths() {
  let results = [];
  let currentDate = new Date();

  let startDate = startOfMonth(currentDate);
  startDate = subMonths(startDate, 1);

  for (let i = 0; i < 12; i++) {
    results.unshift(format(subMonths(startDate, i), 'MMM')); // Use 'MMM' for the abbreviated month name
  }

  return results;
}

export function getPreviousCompareMonth() {
  const now = new Date();
  const twoMonthsAgo = subMonths(now, 2);
  const monthAndYear = format(twoMonthsAgo, "MMM''yy");

  return monthAndYear
}